import React, { useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  BoxProps,
  Button,
  Divider,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  ExpandedIndex,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Stack,
  Switch,
  Text,
  useColorModeValue,
  Avatar,
  useDisclosure
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useForm } from 'react-hook-form';
import _cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import moment from 'moment';
import { parseQuestionLabel } from '../../utils/parseQuestionLabel';

import { userAtom } from 'src/atoms';
import {
  Answer,
  ContractType,
  FlowActionResponse,
  Negotiation,
  NegotiationHistory,
  NegotiationPanelData,
  NegotiationStep,
  NegotiationTerm,
  Pipeline,
  RoleResponse
} from 'src/interfaces';
import { mergeAndSanitizeAnswers } from 'src/utils/mergeAndSanitizeAnswers';
import { parseAnswers } from 'src/utils/parseAnswers';
import { parseConditions } from 'src/utils/parseConditions';
import { ChartaProse } from 'src/components/core/ChartaProse';
import { ChartaText } from 'src/components/core/ChartaText';
import { ChartaField } from 'src/components/form/ChartaField';
import { Check } from 'src/components/icons/Check';
import { Dropdown } from 'src/components/icons/Dropdown';
import { Info } from 'src/components/icons/Info';
import { Notification } from 'src/components/icons/Notification';
import cloneDeep from 'lodash/cloneDeep';
import { addCommasToNumber } from '../../utils/addCommasToNumber';
import { negotiationPanelTheme } from 'src/theme/theme';
import { checkIfCurrentUserIsAttorney } from 'src/api';
import { format, isMatch, parse, parseISO } from 'date-fns';
import _ from 'lodash';
import { moveToNextStage } from 'src/api/pipeline';
import { createContractDocument } from 'src/api/document';
import { StageStepper } from '../core/StageStepper';
import { useNavigate } from 'react-router-dom';
import { AlertModal } from '../core/AlertModal';
import { LoadScreen } from '../core/LoadScreen';

export interface PipelinePanelProps extends BoxProps {
  pipeline: Pipeline;
  onSubmitAnswers: (answers: Answer[], showSuccessModal: boolean) => void;
  onMoveForward: () => void;
  onMoveBackward: () => void;
  onViewContract: () => void;
}

interface AccordionState {
  index: number;
  steps: NegotiationStep[];
}

export const PipelinePanel: React.FC<PipelinePanelProps> = ({
  pipeline,
  onSubmitAnswers,
  onMoveForward,
  onMoveBackward,
  onViewContract,
  ...props
}) => {
  const termStatusesBeforeEditing: any = {};
  const bg = negotiationPanelTheme.boxBackground;
  const termBackground = '#1B1B1B';
  const headerColor = negotiationPanelTheme.headingTextColor;
  const highlightColor = negotiationPanelTheme.highlightColor;
  const textColor = 'white';
  const notificationColor = useColorModeValue('#DE970D', '#F6C547');
  const successColor = useColorModeValue('#159700', '#7BFF66');
  const [isAttorney, setIsAttorney] = useState(false);
  const [wasEditedByAttorney, setWasEditedByAttorney] = useState(false);
  const [pipelineInitiator, setPipelineInitiator] = useState('');
  const [currentUserIsInitiator, setCurrentUserIsInitiator] = useState(false);
  const tableBorderColor = useColorModeValue(
    '#676767',
    'rgba(255, 255, 255, 0.7)'
  );
  const tableTextColor = useColorModeValue(
    '#676767',
    'rgba(255, 255, 255, 0.7)'
  );
  const panelDividerColor = useColorModeValue(
    'rgba(30, 32, 41, 0.8)',
    'rgba(255, 255, 255, 0.1)'
  );
  const offerColor = useColorModeValue('#DE970D', '#F6C547');

  const navigate = useNavigate();

  const isContractSummary = false;
  const [user] = useAtom(userAtom);
  const [newAnswers, setNewAnswers] = useState<Answer[]>([]);
  const [viewStatuses, setViewStatuses] = useState<Record<string, boolean>>({});
  const [historyStatuses, setHistoryStatuses] = useState<
    Record<string, boolean>
  >({});
  const [accordionState, setAccordionState] = useState<AccordionState>({
    index: -1,
    steps: []
  });
  const [tooltipState, setTooltipState] = useState<{
    header: string;
    content: string;
    isOpen: boolean;
  }>({
    header: '',
    content: '',
    isOpen: false
  });
  const [panelData, setPanelData] = useState<NegotiationPanelData>({
    subject: '',
    subjectName: '',
    steps: [],
    referenceAnswers: []
  });

  const termsNegotiated = panelData.steps
    .map((step) => step.counteredCount)
    .reduce((acc, curr) => acc + curr, 0);
  const termsRemaining =
    panelData.steps
      .map((step) => step.negotiatingCount)
      .reduce((acc, curr) => acc + curr, 0) - termsNegotiated;

  const {
    register,
    getValues,
    setValue,
    control,
    watch,
    trigger,
    formState: { errors }
  } = useForm();
  watch();

  const { responses, contractType } = pipeline;
  const {
    name: contractTypeName,
    form: { steps },
    conditions,
    validations,
    nonNegotiable,
    subjectType,
    subjectFieldName
  } = contractType as ContractType;

  const getReferenceAnswers = (
    baseAnswers: Answer[],
    mainAnswers: Answer[]
  ): Answer[] => {
    const base = [...baseAnswers];
    const main = [...mainAnswers];

    for (let i = 0; i < main.length; i++) {
      const j = base.findIndex(
        (answer: Answer) => answer.fieldId === main[i].fieldId
      );
      if (j > -1) {
        base[j] = main[i];
      } else {
        base.push({ ...main[i] });
      }
    }
    return base;
  };

  const formatHistory = (
    response: FlowActionResponse,
    party: any,
    id: string
  ): NegotiationHistory | null => {
    // if (contractType != null && (contractType as ContractType)?.oneSided) {
    //   return null;
    // }
    const answer = response.answers.find(
      ({ questionId }: any) => questionId === id
    );

    if (answer) {
      if (party != null && party.email == user?.email) {
        return {
          name: 'You',
          role: user?.approvalFlowRole?.name,
          imageUrl: '',
          value: answer.value,
          comment:
            typeof answer.comment === 'object'
              ? _.get(answer, 'comment.comment', '')
              : answer.comment,
          date: format(
            new Date(
              response.date != null ? response.date : response.timestamp
            ),
            'M/d/yyyy h:mma'
          )
        };
      }
      let name = `${party.firstName} ${party.lastName}`;
      let role = party?.approvalFlowRole?.name;
      if (isAttorney && party?.email === 'onesided@getcharta.com') {
        name = `${user?.firstName} ${user?.lastName}`;
        role = 'Legal';
      }
      return {
        name,
        role,
        imageUrl: party.profileImageUrl,
        value: answer.value,
        comment:
          typeof answer.comment === 'object'
            ? _.get(answer, 'comment.comment', '')
            : answer.comment,
        date: format(
          new Date(response.date != null ? response.date : response.timestamp),
          'M/d/yyyy h:mma'
        )
      };
    } else {
      return null;
    }
  };

  const getNegotiationTermHistory = (
    questionId: string
  ): NegotiationHistory[] => {
    let history: NegotiationHistory[] = [];

    const newResponse = {
      date: new Date().toString(),
      answers: [...newAnswers]
    };
    let responses = [...pipeline?.flowActions];

    let responsesDone = false;
    let count = 0;
    responses.push(newResponse);

    while (!responsesDone) {
      if (count > responses.length - 1) {
        responsesDone = true;
      } else {
        const response = responses[count];
        const initiatorAnswer = formatHistory(
          response,
          response.user || user,
          questionId
        );
        if (initiatorAnswer) {
          history.push(initiatorAnswer);
        }
      }

      count = count + 1;
    }
    history = history.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      const firstDate = parse(a.date, 'M/d/yyyy h:mma', new Date());
      const first = firstDate.getTime();
      const secondDate = parse(b.date, 'M/d/yyyy h:mma', new Date());
      const second = secondDate.getTime();
      return second - first;
    });
    return history;
  };

  const checkIfUserIsInStage = () => {
    const currentStage = getCurrentStage(pipeline);
    const userRole = user?.approvalFlowRole;
    if (!userRole || userRole._id != currentStage.roleId) {
      return false;
    }
    return true;
  };

  const checkIfUserHasPermissions = (permission: String) => {
    const userRole = user?.approvalFlowRole;

    if (!checkIfUserIsInStage()) {
      return false;
    }

    return userRole.permissions.includes(permission);
  };

  const checkIfDummyField = (answer: any, contractType: any) => {
    if (
      contractType?.listOfFieldsToPlaceDummyDataIn != null &&
      Array.isArray(contractType?.listOfFieldsToPlaceDummyDataIn)
    ) {
      for (const dummyField of contractType.listOfFieldsToPlaceDummyDataIn) {
        if (answer.fieldId === dummyField.fieldId) {
          return true;
        }
      }
    }
    return false;
  };
  const addIsDummyToAnswerArray = (answers: any) => {
    return _cloneDeep(answers).map((answer: any) => {
      // return answer;
      const isDummyField = checkIfDummyField(answer, contractType);
      if (!isDummyField) {
        return answer;
      }
      return {
        ...answer,
        isDummyField
      };
    });
  };

  const getAttorneyState = async () => {
    const attorneyResponse = await checkIfCurrentUserIsAttorney(pipeline._id);
    if (attorneyResponse?.data?.isAttorney == true) {
      setIsAttorney(true);
    } else {
      setIsAttorney(false);
    }
  };

  useEffect(() => {
    getAttorneyState();
    if (Array.isArray(pipeline.flowActions)) {
      for (const action of pipeline.flowActions) {
        if (action.action === 'CREATE_STEPTHROUGH' && action.user != null) {
          setPipelineInitiator(
            `${action.user.firstName} ${action.user.lastName}`
          );
          if (
            user?.approvalFlowRole?._id == action.user.approvalFlowRole?._id
          ) {
            setCurrentUserIsInitiator(true);
          }
        }
      }
    }
  }, [pipeline]);

  const getLatestAnswers = () => {
    return pipeline.responses[pipeline.responses?.length - 1].answers;
  };

  useEffect(() => {
    //Get user latest answers
    const answersToValidate = addIsDummyToAnswerArray(newAnswers);
    const latestAnswers = getLatestAnswers();
    const mappedLatestAnswers = addIsDummyToAnswerArray(latestAnswers);
    const userAnswers = mergeAndSanitizeAnswers(
      mappedLatestAnswers,
      answersToValidate,
      conditions
    );

    const referenceAnswers = userAnswers;

    //Initialize panelData object
    const newPanelData: NegotiationPanelData = {
      subject: subjectType,
      subjectName: parseAnswers(referenceAnswers)[subjectFieldName],
      steps: [],
      referenceAnswers: referenceAnswers
    };

    for (let i = 0; i < steps.length; i++) {
      const step = steps[i];
      newPanelData.steps.push({
        id: step.id,
        counteredCount: 0,
        negotiatingCount: 0,
        terms: []
      });

      for (let j = 0; j < step.subSteps.length; j++) {
        const subStep = step.subSteps[j];
        for (let k = 0; k < subStep.questions.length; k++) {
          const question = subStep.questions[k];

          //Skip this question/term if it is hidden
          if (question.hidden) {
            continue;
          }

          const isNonNegotiable = nonNegotiable.indexOf(question.field.id) > -1;

          //Skip this question/term if condition is not met
          const condition = conditions.questions?.find(
            (c: any) => c[question.id] !== undefined
          );

          if (
            !isNonNegotiable &&
            condition &&
            !parseConditions(condition, parseAnswers(referenceAnswers))
          ) {
            continue;
          }

          const userAnswer = userAnswers.find(
            (answer) => answer.questionId === question.id
          );

          /**
           * Commented this block out on April 24, 2023
           * I assume dthis was no longer necessary
           * and that it was a relic of the previous
           * version of the negotiation panel.
           * There should not be a neeed to skip a question if
           * neither party has responded, given the
           * conditional checks were done prior
           */
          //Skip this question/term if neither party has answered
          // if (
          //   !isNonNegotiable &&
          //   !userAnswer?.value &&
          //   !otherPartyAnswer?.value
          // ) {
          //   continue;
          // }

          const term: NegotiationTerm = {
            step: step.id,
            subStep: subStep.id,
            question: question.id,
            fieldId: question.field.id,
            status: null,
            label:
              question?.summaryLabel != null
                ? question.summaryLabel
                : question.label,
            conditionalLabels: question.conditionalLabels,
            tooltip: question.tooltip,
            prefix: question.field.prefix,
            postfix: question.field.postfix,
            field: question.field //Hack to get field for counter-offer form control
          };

          const isZipCode = term.label?.toLowerCase()?.includes('code'); //Hack to prevent commas from being added to Zip code

          const termValue = userAnswer?.value;

          let value = termValue;

          const newTerm: any = {
            ...term,
            role: '',
            value: addCommasToNumber(value, isZipCode),
            history: getNegotiationTermHistory(question.id)
          };

          if (!isContractSummary) {
            if (
              condition &&
              !parseConditions(condition, parseAnswers(referenceAnswers))
            ) {
              continue;
            }
          } else {
            if (
              condition &&
              !parseConditions(condition, parseAnswers(referenceAnswers))
            ) {
              continue;
            }
          }

          newPanelData.steps[i].terms.push(newTerm);
          continue;
        }
      }
    }

    setPanelData(newPanelData);
  }, [
    conditions,
    pipeline,
    newAnswers,
    nonNegotiable,
    steps,
    subjectFieldName,
    subjectType,
    isContractSummary
  ]);

  useEffect(() => {
    setAccordionState((previous) => {
      if (previous.index === -1) {
        return {
          ...previous,
          steps: [...panelData.steps]
        };
      } else {
        return {
          ...previous,
          steps: [...panelData.steps]
        };
      }
    });
    if (
      Object.keys(viewStatuses).length === 0 &&
      panelData?.steps?.length > 0 &&
      !isContractSummary
    ) {
      const temp = cloneDeep(viewStatuses);
      for (const step of panelData.steps) {
        temp[step.id] = false;
      }
      setViewStatuses(temp);
    }
  }, [isContractSummary, panelData, viewStatuses]);

  const onAcceptOffer = async (term: NegotiationTerm) => {
    setValue(term.fieldId, term.otherPartyAnswerValue);
    const result = await trigger(term.fieldId);
    if (!result) {
      return;
    }

    setNewAnswers((previous) => [
      ...previous,
      {
        stepId: term.step,
        subStepId: term.subStep,
        questionId: term.question,
        fieldId: term.fieldId,
        value: term.otherPartyAnswerValue,
        status: 'new'
      }
    ]);
  };

  const getCurrentStageIndex = (pipeline: Pipeline) => {
    const index = _.findIndex(pipeline?.approvalFlow?.stages, (stage: any) => {
      return stage.name === pipeline.currentStage;
    });
    return index;
  };

  const getCurrentStage = (pipeline: Pipeline) => {
    const stage = _.find(pipeline?.approvalFlow?.stages, (stage: any) => {
      return stage.name === pipeline.currentStage;
    });
    return stage;
  };

  const onCounterOffer = async (term: NegotiationTerm) => {
    const result = await trigger(term.fieldId);
    if (!result) {
      return;
    }
    debugger;
    const answers = [...newAnswers].filter(
      (answer: Answer) => answer.fieldId !== term.fieldId
    );
    let value = getValues()[term.fieldId];
    if (
      term?.field?.component === 'primary-radio' &&
      (value == null || value == '')
    ) {
      value = 'No';
    }
    let comment: string = getValues()[`comment-${term.fieldId}`];
    debugger;
    setNewAnswers((previous) => [
      ...answers,
      {
        stepId: term.step,
        subStepId: term.subStep,
        questionId: term.question,
        fieldId: term.fieldId,
        value,
        status: isAttorney ? 'attorney-edited' : 'new',
        date: format(new Date(), 'M/d/yyyy h:mma'),
        ...(comment != null && {
          comment
        })
      }
    ]);
    if (isAttorney) {
      setWasEditedByAttorney(true);
    }
  };

  const onEditCounterOffer = async (term: any) => {
    const answers = [...newAnswers].filter(
      (answer: Answer) => answer.fieldId !== term.fieldId
    );
    setNewAnswers(answers);
  };

  const editFieldAsAttorney = async (term: any, negotiationStep: any) => {
    onToggleHistoryStatus(term.fieldId, true);
    const newAccordionState = {
      ...accordionState,
      steps: [
        ...steps.map((step: any) => {
          if (step.id !== negotiationStep.id) {
            return accordionState.steps.find((n) => n.id === step.id);
          }
          return {
            ...step,
            terms: [
              ...negotiationStep.terms.map((negTerm: any) => {
                if (negTerm.fieldId === term.fieldId) {
                  return {
                    ...negTerm,
                    status: 'editing'
                  };
                }
                return {
                  ...negTerm
                };
              })
            ]
          };
        })
      ]
    };
    setAccordionState(newAccordionState);
  };

  const onToggleViewStatus = (id: string) => {
    const status = viewStatuses[id];
    if (status === undefined) {
      setViewStatuses((statuses) => ({
        ...statuses,
        [id]: true
      }));
    } else {
      setViewStatuses((statuses) => ({
        ...statuses,
        [id]: !status
      }));
    }
  };

  const onToggleHistoryStatus = (
    id: string,
    historyStatus: boolean | null = null
  ) => {
    if (historyStatus != null) {
      setHistoryStatuses((statuses) => ({
        ...statuses,
        [id]: historyStatus
      }));
      return;
    }
    const status = historyStatuses[id];
    if (status === undefined) {
      setHistoryStatuses((statuses) => ({
        ...statuses,
        [id]: true
      }));
    } else {
      setHistoryStatuses((statuses) => ({
        ...statuses,
        [id]: !status
      }));
    }
  };

  const onToggleAccordion = (index: number) => {
    if (index === -1) {
      setAccordionState({
        index: -1,
        steps: [...panelData.steps]
      });
    } else {
      // const filteredSteps = panelData.steps.filter(
      //   (step, i) => i === index.valueOf()
      // );
      setAccordionState({
        index,
        steps: [...panelData.steps]
      });
    }
  };

  const handleSubmit = async (showSuccessModal = true) => {
    onSubmitAnswers(newAnswers, showSuccessModal);
  };

  const generateContract = async () => {
    try {
      setIsGeneratingContract(true);
      await handleSubmit(false);
      const response = await createContractDocument({
        pipelineId: pipeline._id
      });
      navigate(`/document/${response.data.document._id}`);
    } catch (err) {
      console.error(err);
    } finally {
      setIsGeneratingContract(false);
    }
  };

  const onShowTooltip = (tooltip: any) => {
    setTooltipState({
      ...tooltip,
      isOpen: true
    });
  };

  const onCloseTooltip = () => {
    setTooltipState({
      header: '',
      content: '',
      isOpen: false
    });
  };
  const {
    isOpen: isConfirmationOpen,
    onClose: onCloseConfirmation,
    onOpen: onOpenConfirmation
  } = useDisclosure();
  const {
    isOpen: isSuccessOpen,
    onClose: onCloseSuccess,
    onOpen: onOpenSuccess
  } = useDisclosure();

  const [isGeneratingContract, setIsGeneratingContract] = useState(false);

  if (isGeneratingContract) {
    return <LoadScreen label="Generating contract..." />;
  }
  return (
    <Flex
      flexDirection="column"
      as="form"
      noValidate
      padding={{ base: '8px 8px 8px 8px', xl: '32px 32px 32px 32px' }}
      borderRadius={{ base: '0', xl: '8px' }}
      sx={{ backdropFilter: 'blur(120px)' }}
      filter="drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.25))"
      bg={bg}
      // bg={'red'}
      mx={{ base: '0px', lg: '100px' }}
      h={{ base: '100%', lg: '80%' }}
      overflowY="auto"
      flex="1"
      {...props}
    >
      <AlertModal
        isOpen={isSuccessOpen}
        onAccept={() => {
          onCloseSuccess();
        }}
        acceptText="Continue"
        title="The contract was approved!"
        variant="success"
      />
      <AlertModal
        isOpen={isConfirmationOpen}
        onAccept={() => {
          onCloseConfirmation();
          onOpenSuccess();
          onMoveForward();
        }}
        onDecline={onCloseConfirmation}
        acceptText="Approve"
        declineText="Cancel"
        title="Are you sure you want to approve this contract?"
        text="Doing so will mark the contract as complete."
        variant="warning"
      />
      <Flex
        justifyContent="space-between"
        flexDir={{ base: 'column', lg: 'row' }}
      >
        <Flex
          flexDirection={{ base: 'column', lg: 'row' }}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <Flex flexDirection="column">
            <Heading
              as="h1"
              fontFamily="Poppins"
              fontSize="56px"
              lineHeight="68.4px"
              fontWeight="400"
              mb="6px"
              color={headerColor}
            >
              {panelData.subject}: '{panelData.subjectName}'
            </Heading>
            <Text
              fontFamily="Open Sans"
              fontSize="12px"
              lineHeight="16px"
              fontWeight="400"
              mb="12px"
              color={headerColor}
            >
              Contract Initiator:
              <b>{` ${pipelineInitiator} `}</b> Contract Started:
              <b>{` ${moment(pipeline.createdAt).format('MMM DD, YYYY')}`}</b>
            </Text>
          </Flex>
          <StageStepper pipeline={pipeline} />
        </Flex>
      </Flex>

      <Flex
        flex="1"
        flexDirection="column"
        justifyContent="space-between"
        gap="32px"
      >
        <Accordion
          flex="1"
          allowToggle={true}
          variant="panel"
          onChange={onToggleAccordion}
          index={accordionState.index}
        >
          {steps.map((step: any, index: number) => {
            const negotiationStep = accordionState.steps.find(
              (n) => n.id === step.id
            );

            if (!negotiationStep) {
              return <React.Fragment key={step.id}></React.Fragment>;
            }

            return (
              <AccordionItem key={step.id}>
                {({ isExpanded }) => (
                  <>
                    <AccordionButton bg={'black'}>
                      <Flex justifyContent="space-between" w="100%">
                        <Text
                          fontSize="24px"
                          fontWeight="600"
                          lineHeight="32.68px"
                          color={highlightColor}
                        >
                          {step.title}
                        </Text>
                        {negotiationStep.negotiatingCount === 0 ? (
                          <>
                            {checkIfUserIsInStage() ? (
                              <>
                                <Flex gap="8px" alignItems="center"></Flex>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          !isAttorney && (
                            <>
                              <Flex gap="8px" alignItems="center">
                                <Notification
                                  color={notificationColor}
                                  h="16px"
                                  w="16px"
                                />
                                <Text
                                  color={notificationColor}
                                  fontSize="12px"
                                  lineHeight="16px"
                                  fontWeight="600"
                                  mr="8px"
                                >
                                  Terms Remaining
                                </Text>
                              </Flex>
                            </>
                          )
                        )}
                      </Flex>
                      <Dropdown
                        color={highlightColor}
                        h="4px"
                        w="12px"
                        sx={
                          isExpanded
                            ? {
                                transform: 'rotate(180deg)'
                              }
                            : {}
                        }
                      />
                    </AccordionButton>

                    <AccordionPanel>
                      <Divider
                        orientation="horizontal"
                        border="1px solid"
                        color={panelDividerColor}
                      />

                      <Box h={{ base: '90%' }} overflowY="auto">
                        {[1].map(() => {
                          console.log('@@@', negotiationStep);

                          return <></>;
                        })}
                        {negotiationStep.terms
                          // .filter((term) => {
                          //   // if (!viewStatuses[negotiationStep.id]) {
                          //   //   return true;
                          //   // }

                          //   return (
                          //     term.status === 'negotiating' ||
                          //     term.status === 'countered' ||
                          //     term.status === 'receiver-first' ||
                          //     term.status === 'non-negotiable-input' ||
                          //     term.status === 'non-negotiable-answered'
                          //   );
                          // })
                          // .map((term) => {
                          //   termStatusesBeforeEditing[term.fieldId] =
                          //     term.status;
                          //   return term;
                          // })
                          .map((term) => (
                            <Flex
                              key={term.fieldId}
                              bg={termBackground}
                              padding="24px 24px 16px 24px"
                              mb="12px"
                              flexDirection="column"
                              borderRadius="5px"
                            >
                              <Flex flexDir="column">
                                {/* {term.role && (
                                  <Flex
                                    background="brand.secondary2"
                                    h="25px"
                                    maxWidth="120px"
                                    borderRadius="5px"
                                    alignItems="center"
                                    justifyContent="center"
                                    mb="15px"
                                  >
                                    <Text
                                      fontSize="10px"
                                      fontWeight="700"
                                      lineHeight="12px"
                                      color={highlightColor}
                                    >
                                      {term.role.toUpperCase()}
                                    </Text>
                                  </Flex>
                                )} */}
                                <Flex
                                  gap={{ base: '2px', lg: '8px' }}
                                  alignItems={{
                                    base: 'flex-start',
                                    lg: 'center'
                                  }}
                                  cursor="pointer"
                                  mb="15px"
                                  flexDir={{ base: 'column', lg: 'row' }}
                                >
                                  <ChartaText
                                    answers={panelData.referenceAnswers}
                                  >
                                    {parseQuestionLabel(
                                      term,
                                      parseAnswers(panelData.referenceAnswers)
                                    )}
                                  </ChartaText>
                                  <Flex
                                    h="20.5px"
                                    width="20.5px"
                                    borderRadius="100%"
                                    bg="linear-gradient(#FDE033, #EE4373)"
                                    alignItems="center"
                                    justifyContent="center"
                                    hidden={!term.tooltip}
                                    mt={{ base: '15px', lg: '0' }}
                                    onClick={() => onShowTooltip(term.tooltip)}
                                  >
                                    <Info color="#1B1C31" h="16px" w="18.5px" />
                                  </Flex>
                                </Flex>
                              </Flex>
                              {term.status === 'editing' ? (
                                <Flex justifyContent="space-between">
                                  <Stack>
                                    <Flex gap="8px" alignItems="center">
                                      <Text
                                        color={offerColor}
                                        fontSize="16px"
                                        fontWeight="700"
                                        lineHeight="20px"
                                      >
                                        Your answer: {term.prefix}
                                        {term.userAnswer}
                                        {term.postfix}
                                      </Text>
                                      <Notification
                                        color={notificationColor}
                                        h="16px"
                                        w="16px"
                                      />
                                    </Flex>
                                  </Stack>
                                  <Flex
                                    justifyContent={{
                                      base: 'flex-start',
                                      lg: 'flex-end'
                                    }}
                                    gap="16px"
                                  >
                                    <Button
                                      onClick={() => onCounterOffer(term)}
                                      background={
                                        negotiationPanelTheme.negotiationButtonColor
                                      }
                                    >
                                      Submit
                                    </Button>
                                  </Flex>
                                </Flex>
                              ) : (
                                <Flex justifyContent="space-between">
                                  <Stack>
                                    <Flex gap="8px" alignItems="center">
                                      <Text
                                        color={offerColor}
                                        fontSize="16px"
                                        fontWeight="700"
                                        lineHeight="20px"
                                      >
                                        Current Answer: {term.prefix}
                                        {term.value}
                                        {term.postfix}
                                      </Text>
                                      <Notification
                                        color={notificationColor}
                                        h="16px"
                                        w="16px"
                                      />
                                    </Flex>
                                  </Stack>
                                  <Button
                                    disabled={
                                      !checkIfUserHasPermissions('EDIT_ANSWERS')
                                    }
                                    onClick={() =>
                                      editFieldAsAttorney(term, negotiationStep)
                                    }
                                    background={offerColor}
                                  >
                                    Edit
                                  </Button>
                                </Flex>
                              )}
                              <Flex
                                gap="9px"
                                alignItems="center"
                                mt="16px"
                                mb="7px"
                                onClick={() =>
                                  onToggleHistoryStatus(term.fieldId)
                                }
                                _hover={{ cursor: 'pointer' }}
                                display={
                                  [
                                    'receiver-first',
                                    // 'non-negotiable',
                                    'non-negotiable-input',
                                    'non-negotiable-answered'
                                  ].includes(term.status!)
                                    ? 'none'
                                    : 'flex'
                                }
                              >
                                <Text
                                  color={highlightColor}
                                  fontSize="12px"
                                  fontWeight="600"
                                  lineHeight="16.34px"
                                >
                                  Change History
                                </Text>
                                <Dropdown
                                  color={highlightColor}
                                  h="4px"
                                  w="8px"
                                  sx={
                                    historyStatuses[term.fieldId]
                                      ? {
                                          transform: 'rotate(180deg)'
                                        }
                                      : {}
                                  }
                                />
                              </Flex>

                              {historyStatuses[term.fieldId] && (
                                <>
                                  <>
                                    <Flex
                                      key={index}
                                      alignItems="center"
                                      justifyContent="flex-start"
                                      borderBottom="solid 0.5px"
                                      borderColor={tableBorderColor}
                                      py="8px"
                                      borderTop={index === 0 ? 'solid 1px' : ''}
                                    >
                                      <Flex
                                        gap="12px"
                                        alignItems="center"
                                        flex="1 1 0"
                                      >
                                        <Flex flexDirection="column">
                                          <Text
                                            fontSize="12px"
                                            fontWeight="400"
                                            lineHeight="16px"
                                            color={tableTextColor}
                                            align={{
                                              base: 'right',
                                              lg: 'left'
                                            }}
                                          >
                                            User
                                          </Text>
                                        </Flex>
                                      </Flex>
                                      <Text
                                        flex="1 1 0"
                                        fontSize="12px"
                                        fontWeight="700"
                                        lineHeight="16px"
                                        color={tableTextColor}
                                        align={{
                                          base: 'center',
                                          lg: 'left'
                                        }}
                                      >
                                        Value
                                      </Text>
                                      <Text
                                        flex="1 1 0"
                                        fontSize="12px"
                                        fontWeight="700"
                                        lineHeight="16px"
                                        color={tableTextColor}
                                        align={{
                                          base: 'center',
                                          lg: 'left'
                                        }}
                                      >
                                        Comment
                                      </Text>
                                      <Text
                                        flex="1 1 0"
                                        fontSize="12px"
                                        fontWeight="400"
                                        lineHeight="16px"
                                        color={tableTextColor}
                                        align={{
                                          base: 'right',
                                          lg: 'left'
                                        }}
                                      >
                                        Timestamp
                                      </Text>
                                    </Flex>
                                  </>
                                  <>
                                    {term.status === 'editing' && (
                                      <>
                                        <Flex
                                          key={index}
                                          alignItems="center"
                                          justifyContent="flex-start"
                                          borderBottom="solid 0.5px"
                                          borderColor={tableBorderColor}
                                          py="8px"
                                          borderTop={
                                            index === 0 ? 'solid 1px' : ''
                                          }
                                        >
                                          <Flex
                                            gap="12px"
                                            alignItems="center"
                                            flex="1 1 0"
                                          >
                                            <Avatar
                                              src={''}
                                              size="sm"
                                              display={{
                                                base: 'none',
                                                lg: 'initial'
                                              }}
                                            />
                                            <Flex flexDirection="column">
                                              <Text
                                                fontSize="12px"
                                                fontWeight="400"
                                                lineHeight="16px"
                                                color={tableTextColor}
                                                align={{
                                                  base: 'right',
                                                  lg: 'left'
                                                }}
                                              >
                                                You
                                              </Text>
                                              <Text
                                                fontSize="10px"
                                                fontWeight="700"
                                                lineHeight="12px"
                                                color={tableTextColor}
                                              >
                                                {user?.approvalFlowRole?.name}
                                              </Text>
                                            </Flex>
                                          </Flex>
                                          <Text
                                            flex="1 1 0"
                                            fontSize="12px"
                                            fontWeight="700"
                                            lineHeight="16px"
                                            color={tableTextColor}
                                            align={{
                                              base: 'center',
                                              lg: 'left'
                                            }}
                                          >
                                            <FormControl
                                              key={term.fieldId}
                                              isInvalid={!!errors[term.fieldId]}
                                              w={{ base: '100%', lg: '60%' }}
                                            >
                                              <ChartaField
                                                field={term.field}
                                                register={register}
                                                getValues={getValues}
                                                setValue={setValue}
                                                validations={validations}
                                                control={control}
                                                negotiation={true}
                                                color={'white'}
                                                placeholder="Changes"
                                              />
                                              <FormErrorMessage>
                                                {errors[term.fieldId] &&
                                                  (errors[term.fieldId]
                                                    ?.message as string)}
                                              </FormErrorMessage>
                                            </FormControl>
                                          </Text>
                                          <Text
                                            flex="1 1 0"
                                            fontSize="12px"
                                            fontWeight="700"
                                            lineHeight="16px"
                                            color={tableTextColor}
                                            align={{
                                              base: 'center',
                                              lg: 'left'
                                            }}
                                          >
                                            <FormControl
                                              key={term.fieldId}
                                              isInvalid={!!errors[term.fieldId]}
                                              w={{ base: '100%', lg: '60%' }}
                                            >
                                              <ChartaField
                                                field={{
                                                  component: 'input',
                                                  id: 'comment-' + term.field.id
                                                }}
                                                register={register}
                                                getValues={getValues}
                                                setValue={setValue}
                                                validations={validations}
                                                control={control}
                                                negotiation={true}
                                                color={'white'}
                                                placeholder="Comment"
                                              />
                                              <FormErrorMessage>
                                                {errors[term.fieldId] &&
                                                  (errors[term.fieldId]
                                                    ?.message as string)}
                                              </FormErrorMessage>
                                            </FormControl>
                                          </Text>
                                          <Text
                                            flex="1 1 0"
                                            fontSize="12px"
                                            fontWeight="400"
                                            lineHeight="16px"
                                            color={tableTextColor}
                                            align={{
                                              base: 'right',
                                              lg: 'left'
                                            }}
                                          >
                                            Now
                                          </Text>
                                        </Flex>
                                      </>
                                    )}
                                    {term.history?.map(
                                      (answer: any, index: number) => (
                                        <Flex
                                          key={index}
                                          alignItems="center"
                                          justifyContent="flex-start"
                                          borderBottom="solid 0.5px"
                                          borderColor={tableBorderColor}
                                          py="8px"
                                          borderTop={
                                            index === 0 ? 'solid 1px' : ''
                                          }
                                        >
                                          <Flex
                                            gap="12px"
                                            alignItems="center"
                                            flex="1 1 0"
                                          >
                                            <Avatar
                                              src={answer.imageUrl}
                                              size="sm"
                                              display={{
                                                base: 'none',
                                                lg: 'initial'
                                              }}
                                            />
                                            <Flex flexDirection="column">
                                              <Text
                                                fontSize="12px"
                                                fontWeight="400"
                                                lineHeight="16px"
                                                color={tableTextColor}
                                                align={{
                                                  base: 'right',
                                                  lg: 'left'
                                                }}
                                              >
                                                {answer.name}
                                              </Text>
                                              <Text
                                                fontSize="12px"
                                                fontWeight="400"
                                                lineHeight="16px"
                                                color={tableTextColor}
                                                align={{
                                                  base: 'right',
                                                  lg: 'left'
                                                }}
                                              >
                                                {answer.role}
                                              </Text>
                                            </Flex>
                                          </Flex>
                                          <Text
                                            flex="1 1 0"
                                            fontSize="12px"
                                            fontWeight="400"
                                            lineHeight="16px"
                                            color={tableTextColor}
                                            align={{
                                              base: 'right',
                                              lg: 'left'
                                            }}
                                          >
                                            {addCommasToNumber(
                                              answer.value,
                                              term.label
                                                ?.toLowerCase()
                                                ?.includes('code')
                                            )}
                                          </Text>
                                          <Text
                                            flex="1 1 0"
                                            fontSize="12px"
                                            fontWeight="400"
                                            lineHeight="16px"
                                            color={tableTextColor}
                                            align={{
                                              base: 'right',
                                              lg: 'left'
                                            }}
                                          >
                                            {answer.comment}
                                          </Text>
                                          <Text
                                            flex="1 1 0"
                                            fontSize="12px"
                                            fontWeight="400"
                                            lineHeight="16px"
                                            color={tableTextColor}
                                            align={{
                                              base: 'right',
                                              lg: 'left'
                                            }}
                                          >
                                            {answer.date}
                                          </Text>
                                        </Flex>
                                      )
                                    )}
                                  </>
                                </>
                              )}
                            </Flex>
                          ))}
                      </Box>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            );
          })}
        </Accordion>
        <Flex justifyContent={'space-between'}>
          <Flex>
            {' '}
            <Button
              type="button"
              onClick={() => onMoveBackward()}
              disabled={
                currentUserIsInitiator ||
                pipeline.status == 'completed' ||
                getCurrentStageIndex(pipeline) < 1 ||
                !checkIfUserHasPermissions('MOVE_BACKWARD')
              }
              data-heap-id={`${contractTypeName}.submit-negotiation`}
              color={negotiationPanelTheme.buttonTextColor}
            >
              Send back
            </Button>
          </Flex>

          <Flex justifyContent="end">
            <Button
              type="button"
              disabled={
                getCurrentStageIndex(pipeline) < 1 ||
                !checkIfUserHasPermissions('GENERATE_CONTRACT')
              }
              onClick={() => generateContract()}
              data-heap-id={`${contractTypeName}.submit-negotiation`}
              color={negotiationPanelTheme.buttonTextColor}
              marginRight={50}
            >
              Generate Contract
            </Button>
            <Button
              disabled={
                pipeline.status == 'completed' ||
                !checkIfUserHasPermissions('EDIT_ANSWERS')
              }
              type="button"
              onClick={() => handleSubmit()}
              data-heap-id={`${contractTypeName}.submit-negotiation`}
              color={negotiationPanelTheme.buttonTextColor}
              marginRight={50}
            >
              Save Updates
            </Button>
            <Button
              disabled={
                pipeline.status == 'completed' ||
                (!checkIfUserHasPermissions('MOVE_FORWARD') &&
                  !checkIfUserHasPermissions('REQUEST_APPROVAL'))
              }
              marginRight={50}
              onClick={() => {
                if (checkIfUserHasPermissions('REQUEST_APPROVAL')) {
                  onMoveForward();
                } else {
                  onOpenConfirmation();
                }
              }}
              color={negotiationPanelTheme.buttonTextColor}
            >
              {checkIfUserHasPermissions('REQUEST_APPROVAL')
                ? 'Submit for Approval '
                : 'Approve'}
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <Drawer
        onClose={onCloseTooltip}
        isOpen={tooltipState.isOpen}
        placement="right"
      >
        <DrawerOverlay />
        <DrawerContent p="16px" bg="rgba(27, 28, 49, 1)">
          <Heading fontSize="32px">{tooltipState.header}</Heading>
          <ChartaProse mt="16px">{tooltipState.content}</ChartaProse>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};
